'use strict';

// Constructor
var Catalogo = function() {

    var filters = $('.filters');
    var filterDropdown = $('.filter__drop');
    var categoryDropdowm = $('.filter__head');

    if ( filters ) {
        filterDropdown.each( function(){
            var $this = $(this);
            $this.on('click', function(el){
                if ( $this.hasClass('filter__drop--open') ) {
                    filterDropdown.removeClass('filter__drop--open');
                    el.preventDefault();
                } else {
                    filterDropdown.removeClass('filter__drop--open');
                    $this.addClass('filter__drop--open');
                    el.preventDefault();
                }
            });
        });

        categoryDropdowm.each( function(){
            var $this = $(this);
            $this.on('click', function(el){
                if ( $this.hasClass('filter__head--open') ) {
                    categoryDropdowm.removeClass('filter__head--open');
                    el.preventDefault();
                } else {
                    categoryDropdowm.removeClass('filter__head--open');
                    $this.addClass('filter__head--open');
                    el.preventDefault();
                }
            });
        });
    }
};

module.exports = Catalogo;
