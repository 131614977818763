'use strict';

// Constructor
var Shopcart = function() {
    var selectors = $('[data-target]');
    var tabs = $('[data-tab]');

    function init() {
        $('[data-target]:first').addClass('-active');
        $('[data-tab]:first').addClass('-active');
    }

    if ( selectors && tabs ) {

        selectors.each(function(){
            $(this).on('click',function(){
                var dataTarget = $(this).data('target');

                selectors.removeClass('-active');
                $(this).addClass('-active');

                tabs.removeClass('-active');
                tabs.filter(function() {
                    return $(this).data('tab') == dataTarget;
                }).addClass('-active');
            });
        });

        init();
    }
};

module.exports = Shopcart;
