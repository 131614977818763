// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

global.$ = global.jQuery = require('jquery');
global._ = require('underscore');
var Header = require('../_modules/header/header');
var Slider = require('../_modules/slider/slider');
var Product = require('../_modules/product/product');
var Popular = require('../_modules/popular/popular');
var Catalogo = require('./catalogo');
var ProductShowcase = require('./productShowcase');
var StarRatingInput = require('./starRatingInput');
var Shopcart = require('./shopcart');

$(function() {
    require('../../bower_components/bootstrap-sass/assets/javascripts/bootstrap.min');
    require('../../bower_components/slick-carousel/slick/slick');

    new Header();
    new Slider();
    new Product();
    new Popular();
    new Catalogo();
    new ProductShowcase();
    new StarRatingInput();
    new Shopcart();
});
