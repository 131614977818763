'use strict';

// Constructor
var Product = function() {
    var products = $('.product');

    function setWidth(el, amount) {
        el.css('width', amount + '%')
    }

    if (products) {
        products.each(function(){
            var $this = $(this);
            var rating = $this.find('.product__star-rating__level');
            var rate = rating.data('rating');

            setWidth(rating, rate);
        });


    }
};

module.exports = Product;
