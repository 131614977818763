'use strict';

// Constructor
var StarRatingInput = function() {

    var starRatingInput = $('.product__star-rating--input');

    if ( starRatingInput ) {
        var starRatingLevel = starRatingInput.find('.product__star-rating__level');
        var stars = starRatingLevel.children();
        var input = $('.product__star-rating__value');

        function starSet(index) {
            var i = 0;
            var index = index;
            var value = 0;
            stars.removeClass('-sel');
            while(i < index){
                stars.eq(i).addClass('-sel');
                value = 20 * index;
                input.val(value);
                i++;
            }
        }

        stars.each(function(){
            var $this = $(this);
            var index = $this.index() + 1;

            $this.on('mouseover click', function(){
                starSet(index);
            });
        });
    }
};

module.exports = StarRatingInput;
