'use strict';

// Constructor
var Slider = function() {
    var slider = $('._slider');
    var multislider = $('._multislider');

    slider.each(function(){
        $(this).slick({
            dots: true,
            fade: true,
            arrows:  false,
            autoplay: true
        });
    });

    multislider.each(function(){
        $(this).slick({
            dots: false,
            infinite: true,
            speed: 300,
            slidesToShow: 5,
            slidesToScroll: 5,
            autoplay: true,
            responsive: [
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        centerMode: true,
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });
    });
};

module.exports = Slider;
