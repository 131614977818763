'use strict';

// Constructor
var ProductShowcase = function() {

    var producto = $('.producto');

    if ( producto ) {
        var showMore = $('.producto__showcase__details__show');
        var content = $('.producto__showcase__details__wrapper');
        var more = 'Mostrar más detalles';
        var less = 'Mostrar menos detalles';

        function changeText(el, string){
            var oldText = el.html();
            var newText = string;

            el.data('newtext', oldText);
            el.html(newText);
        }

        showMore.on('click', function(){
            var totalHeight = 0;
            var contentShown = content.data('show');
            content.children().each(function(){
                totalHeight = totalHeight + $(this).outerHeight(true);
            });

            console.log('contentShown: ' + contentShown);

            if ( !contentShown ) {
                content.css('max-height', totalHeight);
                content.data('show', true);
                content.toggleClass('-open');
                changeText(showMore, less);
            }

            if (  contentShown ) {
                content.css('max-height', 100);
                content.data('show', false);
                content.toggleClass('-open');
                changeText(showMore, more);
            }
        });

    }
};

module.exports = ProductShowcase;
